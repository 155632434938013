<script lang="jsx">
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

// 降级组件
import DescriptionDrawerV1 from './components/DescriptionDrawer'
import DescriptionEnterV1 from './components/DescriptionEnter'
import MaterialV1 from 'public/src/pages/goods_detail/middlect/components/Material' // TODO yidier 这个组件作用没找到，可能是废弃了
import DetailFPosV1 from 'public/src/pages/goods_detail/middlect/components/DetailFPos'
import DetailOPosV1 from 'public/src/pages/goods_detail/middlect/components/DetailOPos'
import UserManualEnterV1 from './components/UserManualEnter' // 这两个组件直接替换吧
import UserManualDrawerV1 from './components/UserManualDrawer' // 这两个组件直接替换吧
import BrandSeriesEnterV1 from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/components/BrandSeriesEnter'

// 新组件
import DescriptionDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/DescriptionDrawer.vue'
import DescriptionEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/Enter.vue'
import DetailFPos from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/DetailFPos.vue'
import DetailOPos from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/Description/DetailOPos.vue'
import UserManualEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/UserManualEnter/index.vue'
import UserManualDrawer from 'public/src/pages/goods_detail_v2/innerComponents/drawer/UserManualDrawer.vue'
import NewSizeGuideEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/SizeGuideEnter/index.vue'
import StoreBusinessBrandEntrace from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/StoreBusinessBrandEntrace.vue'

import ModelIntro from 'public/src/pages/goods_detail/middlect/components/ModelIntro'
import NewModelIntro from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/ModelIntro/index.vue'
import SizeGuideEnter from 'public/src/pages/goods_detail/middlect/components/SizeGuideEnter'
import IngredientsEnter from 'public/src/pages/goods_detail/middlect/components/IngredientsEnter'
import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-6-4' })

export default {
  name: 'MiddleAttr',
  components: {
    // 降级组件
    DescriptionDrawerV1,
    DescriptionEnterV1,
    MaterialV1,
    DetailFPosV1,
    DetailOPosV1,
    BrandSeriesEnterV1,
    ModelIntro,
    IngredientsEnter,
    SizeGuideEnter,

    // 新组件
    DetailFPos,
    DetailOPos,
    DescriptionEnter,
    DescriptionDrawer,
    NewSizeGuideEnter,
    StoreBusinessBrandEntrace,
    NewModelIntro,
    UserManualEnter,
    UserManualDrawer,
    NewIngredientsEnter: () => // TODO levi 为啥异步
      import(
        'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/IngredientsEnter/index.vue'
      ),
  },
  computed: {
    ...mapState('newProductDetail/common', ['currentLocalCountry']), // 新数据结构
    ...mapState('newProductDetail', ['descriptionReady', 'userManualReady']),
    ...mapState('newProductDetail/common', [
      'descriptionStatus',
      'currentLocalUnit',
      'detail',
      'userManualStatus'
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
    ...mapGetters('newProductDetail/SizeGuide', ['localsize']),
    ...mapGetters('newProductDetail/common', [
      'parentCats',
      'moduleRenderConfig',
      'showStoreAndBrand',
      'isBrandHasTargetTag',
      'detailFPosContent',
      'isStoreBusinessBrand',
      'brandMapInfo',
      'language',
      'detail',
      'sellingPoints',
      'showSizeGuide',
      'inFashionStoreImg',
      'trendsInfo'
    ]),
    ...mapGetters('newProductDetail/MiddleAttr', [
      'model',
      'pageComponents',
      'sizeInfoDes',
      'showIngredientEnter',
      'showModelIntro',
      'newDetailsArr',
      'orginAttrArray',
      'detailsArr',
      'multiDescription',
      'descriptionList',
      'drawerContent',
      'showEvoluEntry',
      'cccDescAttrList'
    ]),
    // BFF新vuex
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/Description', [
      'description_status',
      'description_ready',
      // 'detail_o_pos_status' // 可能不需要了
    ]),
    ...mapState('productDetail/UserManual', [
      'user_manual_ready',
      'user_manual_status'
    ]),
    ...mapGetters('newProductDetail/SizeBox', [
      'sizeConfig',
    ]),
    ...mapGetters('productDetail/common', [
      'parent_cats',
      'language_v2',
      'goods_id',
      'current_local_country',
    ]),
    ...mapGetters('productDetail/MiddleAttr', [
      'ingredient_text',
      'models_info',
      'show_size_guide_enter',
    ]),
    ...mapGetters('productDetail/Description', [
      'description_name',
      'trends_info',
      'out_side_points',
      'sell_points',
      'description_content',
      'evolu_info',
      'show_evolu',
      'detail_f_pos_box',
      'detail_o_pos_box'
    ]),
    ...mapGetters('productDetail/UserManual', [
      'show_user_manual',
      'user_manual_enter_box',
      'user_manual_drawer_box'
    ]),
    ...mapGetters('productDetail/DetailSignBoard', ['show_store_and_brand']),
    tabCountry() {
      let tabCountry =  this.currentLocalCountry
      if(this.sizeConfig.showOnlyOneLocalSize && this.currentLocalCountry){
        tabCountry = this.currentLocalCountry ===  this.sizeConfig?.sizeRule?.country_code ? this.currentLocalCountry : ''
      }
      return tabCountry
    },
    // 是否展示普通品牌模块（description楼层上面）
    isShowStoreAndBrand() {
      return this.showStoreAndBrand
    },
    showEvolushein() {
      return this.fsAbt?.evolushein?.p?.evolushein === 'show'
    },
    showUserManual() {
      return (
        this.fsAbt?.usermanual?.param?.usermanual === 'show' &&
        this.detail.instruction_url
      )
    },
    // sizeGuidPlanA() {
    //   return (
    //     this.fsAbt?.sizeguideyouhua520?.param?.sizeguideyouhua520 === 'planA'
    //   )
    // },
    sizeGuideAlone() {
      return (
        this.fsAbt?.sizeguideyouhua520?.param?.sizeguide_alone === 'on'
      )
    },
    unitType() {
      const originUnit = this.sizeInfoDes?.sizeUnit != '1' ? 'cm' : 'inch'
      return this.currentLocalUnit || originUnit
    }
  },
  watch: {
    descriptionStatus(newVal) {
      // TODO 旧坑位组件的
      if (newVal) {
        // 卖点曝光埋点
        if (this.sellingPoints?.length) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-35',
            extraData: {
              sellingPoints: this.sellingPoints
            }
          })
        }
      }
    },
    description_status(newVal) {
      // 新坑位组件的
      if (newVal && this.sell_points?.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-2-35',
          extraData: {
            sellingPoints: this.sell_points
          }
        })
      }
    },
    userManualStatus(newVal) { // TODO 旧的说明书点击
      if (newVal) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-219'
        })
      }
    }
  },
  methods: {
    ...mapActions('newProductDetail', [
      'openDescriptionPop',
      'closeDescriptionPop',
      'openUserManualPop',
      'openSizeGuidePop',
      'closeUserManualPop'
    ]),

    // NEW BFF
    ...mapActions('productDetail/Description', [
      'open_description_pop',
      'close_description_pop'
    ]),
    ...mapActions('productDetail/UserManual', [
      'open_user_manual_pop',
      'close_user_manual_pop'
    ]),
    ...mapMutations('productDetail/Description', [
      'update_detail_f_pos_status',
      'update_detail_o_pos_status'
    ]),
    clickManualHandle() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-219'
      })
    }
  },
  render() {
    const CONFIG_V2 = this.MAIN_BFF_APOLLO?.v2
    const CONFIF_V4 = this.MAIN_BFF_APOLLO?.v4
    const { materialConf, descPosConf, sizeGuideConf } =
      this.pageComponents || {}
    const { fPosData, oPosData = [] } = descPosConf || {}
    const showMaterial =
      materialConf?.isShow &&
      fPosData?.isShow &&
      materialConf?.materialList?.length

    const showDetailPos =
      fPosData?.isShow &&
      (this.detailFPosContent?.textAttr?.length ||
        this.detailFPosContent?.imgAttr?.length ||
        oPosData?.length) // 过滤cv属性存在没有值的情况
    const showModule =
      fPosData?.showModule == 1 && (showMaterial || showDetailPos)
    if (
      typeof window !== 'undefined' &&
      window.SaPageInfo &&
      window.SaPageInfo.page_param
    ) {
      window.SaPageInfo.page_param.is_include_cv = this.orginAttrArray.hasCVAttr
        ? 1
        : 0 // 商详页埋点设置page_name
    }

    // sizeGuideAlone为true，当没有模特信息时，隐藏Describtion下方的Size Guide入口
    const showSizeGuideEnter = this.show_size_guide_enter && !this.sizeGuideAlone
    // sizeGuide楼层入口
    const renderSizeGuideEnter = () => {
      if (this.MAIN_BFF_APOLLO?.v4) {
        return showSizeGuideEnter ? <NewSizeGuideEnter
          classStr="product-middle__has-border"
          goodsId={this.goods_id}
          title={this.language_v2?.SHEIN_KEY_PWA_15005}
          openSizeGuidePop={this.openSizeGuidePop}
        /> : null
      }
      if (this.showSizeGuide && sizeGuideConf?.isShow) {
        return <SizeGuideEnter class="product-middle__has-border" />
      }
      return null
    }

    // 美妆成分入口
    const renderIngredientEnter = () => {
      if (this.MAIN_BFF_APOLLO?.v1) {
        return this.ingredient_text ? (
          <NewIngredientsEnter
            language={this.language}
            ingredientText={this.ingredient_text}
          />
        ) : null
      }
      if (this.showIngredientEnter) {
        return <IngredientsEnter />
      }
      return null
    }

    // 商详描述坑位信息降级
    const descriptionEnterContent = () => {
      if (CONFIG_V2) {
        return (
          <DescriptionEnter
            openDescriptionPopFn={this.open_description_pop}
            descriptionName={this.description_name}
            pointsData={this.out_side_points}
            box-style={{
              'border-top':
                showModule || this.isShowStoreAndBrand || this.show_user_manual
                  ? '1px solid #e5e5e5'
                  : 'none'
            }}
            trendsInfo={this.trends_info}
            goodsId={this.detail.goods_id}
          />
        )
      }

      return (
        <DescriptionEnterV1
          details-arr={this.detailsArr}
          new-details-arr={this.newDetailsArr}
          language={this.language}
          pageComponents={this.pageComponents}
          fsAbt={this.fsAbt}
          sellingPoints={this.sellingPoints}
          openDescriptionPopFn={this.openDescriptionPop}
          cccDescAttrList={this.cccDescAttrList}
          box-style={{
            'border-top':
              showModule || this.isShowStoreAndBrand || this.showUserManual
                ? '1px solid #e5e5e5'
                : 'none'
          }}
          trendsImg={this.inFashionStoreImg}
          trendsInfo={this.trendsInfo}
          goodsId={this.detail.goods_id}
        />
      )
    }

    const renderModelIntro = () => {
      if (!this.showModelIntro) return null

      if (CONFIG_V2) {
        return (
          <NewModelIntro
            currentLocalCountry={this.current_local_country}
            parentCats={this.parent_cats}
            modelContent={this.models_info}
            language={this.language}
            isClick={this.sizeGuideAlone && this.show_size_guide_enter}
            class={this.sizeGuideAlone && this.show_size_guide_enter ? 'model-intro__alone' : ''}
            style={{
              paddingTop: this.show_size_guide_enter ? '0' : '.32rem'
            }}
            v-on:clickModelIntro={this.openSizeGuidePop}
          />
        )
      }

      return (
        <ModelIntro
          currentLocalCountry={this.tabCountry}
          localsize={this.localsize}
          parentCats={this.parentCats}
          moduleRenderConfig={this.moduleRenderConfig}
          modelContent={this.model}
          language={this.language}
          unitType={this.unitType}
          sizeGuidPlanA={true}
          style={{
            paddingTop:
              this.sizeInfoDes?.sizeInfo?.length && sizeGuideConf.isShow
                ? '0'
                : '.32rem'
          }}
        />
      )
    }

    const descriptionDrawerContent = () => {
      if (CONFIG_V2) {
        if (!this.description_status) return null
        return (
          <DescriptionDrawer
            show-drawer={this.description_status}
            closeDescriptionPop={this.close_description_pop}
            content={this.description_content}
            title={this.description_name}
            sellingPoints={this.sell_points}
            $router={this.$router}
            trendsInfo={this.trends_info}
            showEvolu={this.show_evolu}
            evoluInfo={this.evolu_info}
            goodsId={this.detail.goods_id}
            catId={this.detail.cat_id}
          />
        )
      } else {
        if (!this.descriptionReady) return null
        return (
          <DescriptionDrawerV1
            show-drawer={this.descriptionStatus}
            closeDescriptionPop={this.closeDescriptionPop}
            showEvoluEntry={this.showEvoluEntry}
            showEvolushein={this.showEvolushein}
            language={this.language}
            multiDescription={this.multiDescription}
            descriptionList={this.descriptionList}
            content={this.drawerContent}
            title={this.pageComponents?.descriptionConf?.descriptionName || ''}
            sellingPoints={this.sellingPoints}
            $router={this.$router}
            trendsInfo={this.trendsInfo}
            trendsImg={this.inFashionStoreImg}
            detail={this.detail}
          />
        )
      }
    }

    // 详情坑位模板
    const detailTemplatePositionContent = () => {
      if (CONFIG_V2) {
        // status={this.detail_o_pos_status} // 可能不需要了
        return (
          <div>
            {this.detail_f_pos_box ? (
              <DetailFPos
                content={this.detail_f_pos_box}
                openDetail={this.update_detail_f_pos_status}
                openDescription={this.open_description_pop}
              />
            ) : null}
            {this.detail_o_pos_box ? (
              <DetailOPos
                content={this.detail_o_pos_box}
              />
            ) : null}
          </div>
        )
      }
      return (
        <div>
          {showMaterial ? <MaterialV1 /> : null}
          {showDetailPos ? <DetailFPosV1 /> : null}
          {showDetailPos ? <DetailOPosV1 /> : null}
        </div>
      )
    }

    // 用户信息模板坑位
    const userManualEnterContent = (boxStyle = {}) => {
      if (CONFIG_V2) {
        if (!this.show_user_manual) return null
        return (
          <UserManualEnter
            title={this.user_manual_enter_box.title}
            content={this.user_manual_enter_box.content}
            openUserManualPopFn={() => this.open_user_manual_pop(this.clickManualHandle)}
            box-style={boxStyle}
          />
        )
      }
      if (!this.showUserManual) return null
      return (
        <UserManualEnterV1
          language={this.language}
          openUserManualPopFn={this.openUserManualPop}
          box-style={boxStyle}
        />
      )
    }

    // 用户信息弹窗
    const userManualDrawerContent = () => {

      // user_manual_ready

      if (CONFIG_V2) {
        if (!this.user_manual_ready) return null
        return (
          <UserManualDrawer
            show-drawer={this.user_manual_status}
            closeUserManualPop={this.close_user_manual_pop}
            content={this.user_manual_drawer_box}
          />
        )
      }

      if (!this.userManualReady) return null
      return <UserManualDrawerV1
        key={this.detail.instruction_url}
        show-drawer={this.userManualStatus}
        closeUserManualPop={this.closeUserManualPop}
        language={this.language}
        content={this.detail.instruction_url}
      />
    }

    // 品牌独立入口
    const storeBusinessBrandEntraceContent = () => {
      if (CONFIF_V4) {
        if (!this.show_store_and_brand) return null
        return <StoreBusinessBrandEntrace config={{ v1: true }}/>
      } else {
        if (!this.isShowStoreAndBrand) return null
        return <BrandSeriesEnterV1
          data={this.brandMapInfo}
          is-store-brand={true}
          hide-jump-text={true}
        />
      }

    }

    return (
      <div>
        <div>
          {showModule ? (
            <div class="goods-attr__title">Product Details</div>
          ) : null}
          {/* 商品说明书信息模板坑位 */}
          {showModule
            ? userManualEnterContent({
              'border-bottom':
                  showModule || this.isShowStoreAndBrand
                    ? '1px solid #e5e5e5'
                    : 'none'
            })
            : null}
          {/* 详情坑位模板 */}
          {detailTemplatePositionContent()}
        </div>
        {/* 商品说明书信息模板坑位 */}
        {!showModule ? userManualEnterContent({}) : null}
        {storeBusinessBrandEntraceContent()}
        {descriptionEnterContent()}
        {/* Drawer */}
        {descriptionDrawerContent()}
        {userManualDrawerContent()}
        {renderIngredientEnter()}
        {renderSizeGuideEnter()}
        {renderModelIntro()}
      </div>
    )
  }
}
</script>

<style lang="less">
.goods-attr {
  &__title {
    font-weight: bold;
    .font-dpr(28px);
    line-height: 1.14;
    padding: 0.2667rem 0 0;
    /* rw:begin */
    font-family: 'Adieu';
  }
}

.product-middle {
  &__has-border {
    border-top: 1px solid #e5e5e5;
  }
}
</style>
