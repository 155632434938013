<template>
  <div class="signboard__container split">
    <template v-if="config.showStoreBrand">
      <!-- 品牌集成店铺 -->
      <StoreEnter
        :data="signData"
        :analysis="productItemsConfig.sa"
        :set-adp-params="showList"
        :flash-entry-type="flashEntryType"
        @handleStoreBrandAnalysis="handleStoreBrandAnalysis"
      />
    </template>
    <template v-else>
      <!-- 第三方店铺 ｜ 品牌 ｜ 系列 -->
      <template v-if="MAIN_BFF_APOLLO.v4">
        <BrandSeriesEnter
          :class="showList && 'have-list'"
          :has-recommend-list="showList"
          :analysis="productItemsConfig.sa"
          :set-adp-params="showList"
          :flash-entry-type="flashEntryType"
          @handleStoreBrandLabel="handleStoreBrandLabel"
          @handleStoreBrandAnalysis="handleStoreBrandAnalysis"
        />
      </template>
      <template v-else>
        <BrandSeriesEnterV1
          :class="showList && 'have-list'"
          :has-recommend-list="showList"
          :data="signData"
          :analysis="productItemsConfig.sa"
          :set-adp-params="showList"
          :flash-entry-type="flashEntryType"
          @handleStoreBrandLabel="handleStoreBrandLabel"
          @handleStoreBrandAnalysis="handleStoreBrandAnalysis"
        />
      </template>
    </template>
    <div
      v-if="storehotnewsType === 'showalone' && showList"
      class="signboard__container__atmosphere"
    >
      <span class="signboard-atmosphere-title">{{
        language.SHEIN_KEY_PWA_32249 || 'Store Recommend'
      }}</span>
      <StoreAtmosphereSwiper />
    </div>
    <div 
      v-if="flashEntryType === 'showbelt' && signData.type === 'store'"
      class="signboard__container__flash-entry"
      @click="clickFlashBeltEntry()"
    >
      <div class="flash-entry-iconwrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8792 1.6001H6.39922L3.19922 7.46676H7.03922L6.07922 14.4001L12.7992 4.8001H8.95922L10.8792 1.6001Z"
            fill="black"
          />
        </svg>
        <span class="flash-entry-text">{{
          language.SHEIN_KEY_PWA_31987 || 'This store is in Flash Sale'
        }}</span>
      </div>
      <div
        v-if="oneLineShow"
        ref="rightText"
      >
        <div
          v-if="flashEntryCountdown"
          class="flash-entry-countdown"
        >
          <p v-html="countdownTips"></p>
        </div>
        <div v-else>
          {{ language.SHEIN_KEY_PWA_31986 || 'Don’t miss it' }}
        </div>
      </div>
      <Icon
        class="flash-entry-rightarrow"
        name="sui_icon_more_right_16px_2"
        size="16px"
        :is-rotate="GB_cssRight"
      />
    </div>
    <template v-if="showList">
      <SignBoardSlide
        v-if="isSlide"
        ref="SLIDE_REF"
        v-bind="listParams"
        da-expose-code="board_expose_code"
        :showStoreBrand="config.showStoreBrand"
        :store-jump-type="storeJumpType"
        :storeBrandLabel="storeBrandLabel"
        :fourProdecutItemConfig="fourProdecutItemConfig"
      />
      <SignBoardList
        v-else
        ref="LIST_REF"
        v-bind="listParams"
        da-expose-code="board_expose_code"
      />
    </template>

    <!-- 品类聚合推荐 todo -->
    <CategoryRecommend
      v-if="showStore && isShowStoreCategory"
      :data="signData"
      :set-adp-params="showList"
      :language="language"
    />
  </div>
</template>

<script>
import productItemMixin from 'public/src/pages/components/product/item_v2/js/mixin'
import BrandSeriesEnterV1 from './components/BrandSeriesEnter'
import BrandSeriesEnter from 'public/src/pages/goods_detail_v2/innerComponents/middle/innerComponnets/DetailSignBoard/BrandSeriesEnter.vue'

import StoreEnter from './components/StoreEnter'
import SignBoardSlide from './components/SignBoardSlide'
import SignBoardList from './components/SignBoardList'
import StoreAtmosphereSwiper from './components/StoreAtmosphereSwiper'
import CategoryRecommend from './components/CategoryRecommend'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { SHOW_GOOD_LEN, SHOW_GOOD_LIST_LEN } from './constants'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'




import {
  useItemConfig,
  EItemConfigType
} from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index.js'
import {
  stringifyQueryString,
  CountDown,
  debounce
} from '@shein/common-function'
const daEventExpose = daEventCenter.getExposeInstance()
const { langPath } = gbCommonInfo
import { Icon } from '@shein-aidc/icon-vue2'
import pageContextConstant from 'public/src/pages/goods_detail/utils/pageContextConstant.js'
export default {
  name: 'DetailSignBoard',
  components: {
    // 旧的
    BrandSeriesEnterV1,
    // 新的
    BrandSeriesEnter,
    SignBoardSlide,
    SignBoardList,
    StoreEnter,
    CategoryRecommend,
    Icon,
    StoreAtmosphereSwiper
  },
  mixins: [productItemMixin],
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    signData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      goodsItemInfo: null,
      itemLanguage: {},
      isInit: true,
      itemAbt: {},
      goodsList: [],
      storeBrandLabel: '',
      extAnalysisInfo: {},
      countTimer: null,
      secondInterval: null,
      secondHundred: 9,
      beginTime: 0,
      oneLineShow: true
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['quickShip']),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/Signboard', [
      'isShowStoreCategory',
      'cccStoreGoodsRecIntervention',
      'flashEntryType',
      'flashEntryCountdown',
      'storehotnewsType'
    ]),
    ...mapGetters('newProductDetail/common', [
      'language',
      'productInfo',
      'detail',
      'mallCode',
      'productItemsLanguage',
      'storeRecommendInfo',
      'boardList',
      'showStore',
      'complianceMode',
      'isInFashionStore',
      'isStoreBusinessBrand',
      'hasBrandSeriesDetailData',
      'isHitComplianceMode',
      // 是否是品质店铺
      'isQualityStore'
    ]),
    ...mapGetters('newProductDetail/ShippingEnter', ['storeInfo']),
    ...mapGetters('productDetail/common', ['hide_estimated_price_item_config']),

    // NEW BFF
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    countdownTips() {
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      let hundredTxt = `<span class="second-hundred">${this.secondHundred}</span>`
      return (
        spanTxt(H) +
        'h : ' +
        spanTxt(M) +
        'm : ' +
        spanTxt(S) +
        's' +
        hundredTxt
      )
    },
    siteMallInfo() {
      return this.productInfo?.siteMallInfo || []
    },
    showQuickTip() {
      return this.fsAbt?.AllListQuickShip?.param === 'type=B'
    },
    storeJumpType() {
      return 'fourtall'
    },
    STYLE() {
      return this.config?.style
    },
    position() {
      return this.config?.position
    },
    showStore() {
      const showStore =
        !!(
          [1, 2, 6].includes(+this.signData.storeType) &&
          this.signData.storeStatus
        ) || this.config.showStoreBrand
      return showStore
    },
    showList() {
      const { STYLE, boardList } = this
      if (STYLE === 'default') return false
      // 单行10个，双行不小于6个
      if (
        this.isSlide
          ? boardList.length < SHOW_GOOD_LEN
          : boardList.length < SHOW_GOOD_LIST_LEN
      )
        return false

      const storerecommendAbt = this.fsAbt?.storerecommend?.p?.storerecommend
      if (storerecommendAbt === 'show-all') return true
      if (storerecommendAbt === 'show-limited') {
        // 限制仅时尚&品牌店铺&品质店铺能展示推荐
        if (
          this.isInFashionStore ||
          this.isStoreBusinessBrand ||
          this.isQualityStore
        )
          return true
        // 非店铺，模块是品牌/系列模块展示
        if (!this.isStore && this.hasBrandSeriesDetailData) return true
      }
      return false
    },
    isSlide() {
      const { STYLE } = this
      return ['A1', 'A2'].includes(STYLE)
    },
    localShipping() {
      const mall_tags = this.getMallTag(this.mallCode)
      return (
        mall_tags.indexOf('5') > -1 &&
        mall_tags.indexOf('4') == -1 &&
        this.siteMallInfo.length > 1
      )
    },
    isStore() {
      return this.signData.type === 'store'
    },
    isFour() {
      return (
        ['fourshort', 'fourtall'].includes(this.storeJumpType) && this.isStore
      )
    },
    // 新店招商卡配置、及埋点信息
    fourProdecutItemConfig() {
      return {
        showBadge: false,
        disableMainimgJump: true,
        itemDAEventClickId: '1-6-4-89',
        itemDAEventExposeId: '1-6-4-95',
        sa: {
          mall_code: this.mallCode
        },
        ...this.hide_estimated_price_item_config
      }
    },
    productItemsConfig() {
      const { signData, mallCode, detail } = this
      const src_identifier = [
        `on=${signData.type}`,
        `cn=${signData.name}`,
        `hz=0`,
        `ps=1_0`,
        `jc=${
          signData.type === 'store' ? 'thirdPartyStoreHome_' : 'itemPicking_'
        }${detail.goods_id}`
      ].join('`')

      const { itemConfig } = useItemConfig({
        name: 'StoreRec',
        type: EItemConfigType.Pic3,
        isRecommend: true,
        mergeConfig: {
          style: {
            pricesNewline: true
          },
          sa: {
            activity_from: 'brand_goods_list',
            style: 'detail',
            src_module: 'DetailBrand',
            src_identifier,
            mall_code: mallCode,
            quickship_tp: this.quickShip ? 1 : 0,
            localshipping_tp: this.localShipping ? 1 : 0,
          },
          showAddBagBtn: false,
          itemDAEventExposeId: '1-6-4-49',
          itemDAEventClickId: '1-6-4-50',
          itemDAEventExposeViewMoreId: '1-6-4-47',
          itemDAEventClickViewMoreId: '1-6-4-48',
          disableMainimgJump: true,
          hideDiscountLabel: false, // 隐藏折扣角标
          hideBottomCccTspBadge: true,
          ...this.hide_estimated_price_item_config
        }
      })
      return itemConfig.value
    },
    listParams() {
      const {
        goodsList,
        goodsItemInfo,
        productItemsConfig,
        language,
        itemLanguage,
        signData,
        constantData,
        isFour,
        isStore
      } = this

      // ＞10个，才有view more和跳转入口
      const showMore = this.boardList.length > SHOW_GOOD_LEN
      return {
        products: goodsList,
        goodsItemInfo,
        productItemsConfig,
        language,
        itemLanguage,
        signData,
        constantData,
        showMore,
        isFour,
        isStore
      }
    }
  },
  watch: {
    boardList: {
      immediate: true,
      handler(val) {
        if (val.length && this.isInit && this.showList) {
          this.isInit = false
          this.goodsList = []
          this.$nextTick(() => {
            // 不得超过最大数量
            this.goodsList = val.slice(
              0,
              this.isSlide ? SHOW_GOOD_LEN : SHOW_GOOD_LIST_LEN
            )
            this.initProduct(this.goodsList)
          })
        }
      }
    },
    flashEntryCountdown() {
      this.initTimer()
      this.calcuRightText()
    },
    flashEntryType() {
      this.calcuRightText()
    }
  },
  async mounted() {
    // this.itemAbt = ( await abtservice.getUserAbtResult({ newPosKeys: 'pricemember,discountLabel,greysellingPoint' }) ) || {}
    const newPosKeys = [
      'pricemember',
      'discountLabel',
      'greysellingPoint',
      'listtagSorting',
      'listrankingTag'
    ]
    const abtResult = await getUserAbtData()
    this.itemAbt = newPosKeys.reduce(
      (acc, poskey) => Object.assign(acc, { [poskey]: abtResult[poskey] }),
      {}
    )
    this.initTimer()
    this.calcuRightText()
  },
  created(){
    // this.updateAtmosphereInfo() // 统一放到updataHot中处理
  },
  methods: {
    // ...mapMutations('productDetail', ['assignState']),
    // ...mapActions('newProductDetail/Signboard', ['updateBffInfo']),
    // updateAtmosphereInfo(){
    //   let closeBff = pageContextConstant.get('closeBff')
    //   if(closeBff){ // TODO DELETE 0813 SSR-BFF更新删除
    //     this.updateBffInfo() // 等ssr也兼容bff的realtime接口时，此处请求可删除。
    //   }
    // },
    calcuRightText() {
      this.$nextTick(() => {
        let el = this.$refs.rightText
        if (el?.offsetHeight > 30) {
          // 一行展示不下，则隐藏右测内容
          this.oneLineShow = false
        } else {
          this.oneLineShow = true
        }
      })
    },
    initTimer() {
      if (!this.flashEntryCountdown) return
      // 倒计时开始时间
      this.countTimer = new CountDown()
      this.beginTime = this.flashEntryCountdown
      if (this.beginTime <= 0) {
        this.beginTime = 0
      }
      this.secondInterval = setInterval(() => {
        this.secondHundred--
        if (this.secondHundred < 0) this.secondHundred = 9
      }, 100)

      // 初始化倒计时
      this.countTimer.start({
        seconds: this.beginTime / 1000,
        endFunc: debounce({
          func: () => {
            this.clearTimeAndInterval()
            window?.location.reload()
          }
        })
      })
    },
    clearTimeAndInterval() {
      // 清除定时器
      this.countTimer && this.countTimer.clear()
      this.secondInterval && clearInterval(this.secondInterval)
    },
    clickFlashBeltEntry() {
      const { type, id, link, name, designerId, scId } = this.signData
      const analysis = this.productItemsConfig.sa
      if (!link) return
      const src_identifier = [
        `on=${type}`,
        `cn=${name}`,
        `hz=0`,
        `ps=1_1`,
        `jc=${
          designerId
            ? `sheinxDesignerInformation_${designerId}`
            : type === 'store'
              ? `thirdPartyStoreHome_${id}`
              : `itemPicking_${scId}`
        }`
      ].join('`')

      const params = {
        src_module: analysis?.src_module,
        src_identifier: src_identifier,
        src_tab_page_id: getSaPageInfo.tab_page_id || '',
        main_goods_id: this.detail.goods_id || '',
        main_cate_id: this.detail.cat_id || '',
        rule_poskey: 'DetailShopItemList',
      }
      this.clickStoreReports({ type, id, src_identifier })
      let url = `${langPath}/store/home?store_code=${
        this.signData.storeCode || this.signData.store_code
      }&tabId=promotion&isStoreTabSticky=1&adp=${
        this.detail.goods_id
      }&${stringifyQueryString({
        queryObj: params
      })}`
      this.$router.push(url)
    },
    clickStoreReports({ type, id, src_identifier }) {
      const { brandInfo, brandLabel, buttonLabel } = this.extAnalysisInfo || {}
      daEventCenter.triggerNotice({
        daId: '1-6-1-193',
        extraData: {
          brand_type: type,
          brand_code: id,
          brand_info: brandInfo,
          brand_label: brandLabel,
          button_label: buttonLabel,
          page_nm: 'page_goods_detail',
          src_identifier,
          flash_sale_stype: 1
        }
      })
    },
    exposeCallback(params) {
      recommendExposureListInstance.pushQueen({
        targets: params?.targets || []
      })
    },
    handleStoreBrandLabel(label) {
      this.storeBrandLabel = label
    },
    handleStoreBrandAnalysis(info) {
      this.extAnalysisInfo = info
    },
    async initProduct() {
      // 天杀的多语言，此处传给了商品项
      const language = this.productItemsLanguage
      this.itemLanguage = language || {}
      this.initAnalysis()
    },
    getMallTag(mall_code) {
      return (
        this.siteMallInfo.find(_ => _.mall_code == mall_code)?.mall_tags || ''
      )
    },
    initAnalysis() {
      const { isSlide, handleExpose } = this
      this.$nextTick(() => {
        this.$refs[isSlide ? 'SLIDE_REF' : 'LIST_REF']?.setAnalysis?.()
        handleExpose()
      })
    },
    handleExpose() {
      let configType = this.isFour
        ? 'fourProdecutItemConfig'
        : 'productItemsConfig'
      daEventExpose.subscribe({
        keycode: `board_expose_code\`${this[configType].itemDAEventExposeId}`,
        type: 'list',
        hooks: {
          afterExpose: params => {
            recommendExposureListInstance.pushQueen({
              targets: params?.targets || []
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.signboard__container {
  background: @sui_color_white;
  padding-bottom: 0.1px;
  &.split {
    margin-top: 0.213333rem;
  }
  &__atmosphere {
    display: flex;
    justify-content: space-between;
    height: 0.37333rem;
    line-height: 0.37333rem;
    padding: 0 0.32rem;
    margin-bottom: 0.21rem;
    .signboard-atmosphere-title {
      .text-overflow();
      margin-right: 0.53333rem;
      max-width: 4.53rem;
    }
  }
  &__flash-entry {
    width: 9.36rem;
    height: 0.8rem;
    margin: 0 auto 0.21rem;
    background-image: url('/pwa_dist/images/flash-sale/flash-belt-bg-0b33a05097.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 0.1067rem;
    display: flex;
    align-items: center;
    padding: 0 0.21rem;
  }
  .flash-entry-iconwrap {
    display: flex;
    align-items: center;
    font-size: 0.32rem;
    flex: 1;
    svg {
      flex: 0 0 auto;
    }
    .flash-entry-text {
      margin-left: 0.096rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 7.8rem;
    }
  }
  .flash-entry-rightarrow {
    margin-left: 0.106rem;
  }
  .flash-entry-countdown {
    font-size: 0.293rem;
    .second-hundred {
      font-size: 0.32rem;
      padding: 0 0.0872rem;
      background: #000;
      color: #fff;
      border-radius: 0.053rem;
      margin-left: 0.106rem;
    }
  }
}
/* 调整底部有列表时的店铺间距 */
.have-list {
  padding-bottom: 16/75rem;
}
</style>
