<template>
  <div
    ref="attrBox"
    class="goods-attr"
    :class="{ more: attrMore || attrMore === null }"
  >
    <div
      class="j-sa-detailfpos"
      da-event-click="1-6-4-36"
      data-position="1"
      :data-status="content.showModule == 0 ? 'close' : 'open'"
    >
      <div
        v-if="content.showModule == 0"
        aria-hidden="true"
      >
        <div
          class="goods-attr__opos j-push-history-hash"
          state="detailFPos"
          @click="openDetail(true)"
        >
          {{ content.title }}
          <i class="suiiconfont sui_icon_more_right2_16px"></i>
        </div>
      </div>
      <div v-else>
        <div class="goods-attr__stitle">
          {{ content.title }}
        </div>
        <DetailFPosContent
          ref="attrWrap"
          :content="content.sub_content"
          :idx="1"
        >
          <template
            v-if="content.evlou_floor.show"
            slot="middle"
          >
            <EvoluSheinBar
              :box-style="{ marginTop: 0 }"
              :content="content.evlou_floor"
              @bar-click="openDescription"
            />
          </template>
        </DetailFPosContent>
      </div>
    </div>
    <div
      v-if="attrMore !== null"
      :class="['goods-attr__more', attrMore ? '' : 'goods-attr__more-less']"
      @click="changeAttrMore"
    >
      {{
        attrMore ? textContent.showMoreText : textContent.showLessText
      }}
      <i
        class="iconfont"
        :class="[attrMore ? 'icon-down' : 'icon-shang']"
      ></i>
    </div>

    <DetailFPosDrawer
      v-if="content.showModule == 0"
      :content="content.sub_content"
      :idx="1"
    />
  </div>
</template>
<script>
import DetailFPosContent from './DetailFPosContent.vue'
import EvoluSheinBar from './EvoluSheinBar'

export default {
  components: {
    DetailFPosContent,
    DetailFPosDrawer: () =>
      import(
        'public/src/pages/goods_detail_v2/innerComponents/drawer/DetailFPosDrawer.vue'
      ),
    EvoluSheinBar
  },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    openDetail: {
      type: Function,
      default: () => {}
    },
    openDescription: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      attrMore: null
    }
  },
  computed: {
    textContent () {
      return this.content.common_text_content || {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.content.showModule == 1) {
        this.attrMore =
          this.$refs.attrWrap.$el.offsetHeight > this.$refs.attrBox.offsetHeight
            ? true
            : null
      }
    })
  },
  methods: {
    changeAttrMore() {
      this.attrMore = !this.attrMore
    }
  }
}
</script>

<style lang="less">
.goods-attr {
  position: relative;
  overflow-y: scroll;
  &.more {
    max-height: 7.84rem;
  }
  &__more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.066667rem;
    background: white;
    text-align: center;
    line-height: 1.066667rem;
    .font-dpr(24px);
    color: #767676;
    vertical-align: middle;
    &::before {
      content: '';
      position: absolute;
      top: -0.64rem;
      left: 0;
      right: 0;
      display: block;
      height: 0.64rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
      );
    }
  }
  &__more-less {
    position: relative;
    &::before {
      height: 0;
      top: 0;
    }
  }
  &__stitle {
    .font-dpr(28px);
    line-height: 1.14;
    padding: 0.32rem 0;
  }
}
</style>
