<script lang="jsx">
import { mapGetters, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { transformImg } from '@shein/common-function'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { useItemConfig, EItemConfigType } from 'public/src/pages/goods_detail/RecommendRelatives/ProductConfigManager/index'

// 只在客户端环境中注册 swiper

daEventCenter.addSubscriber({ modulecode: '1-6-4' })
const daEventExpose = daEventCenter.getExposeInstance()

const { LAZY_IMG } = gbCommonInfo

export default {
  name: 'StyleToMatchGroups',
  setup() {

    const { itemConfig } = useItemConfig({
      useNewAbt: false,
      name: 'GetTheLookPopup',
      type: EItemConfigType.Pic1,
      mergeConfig: {
        showAddBagBtn: true,
        showAddBagBtnBottomRight: false,
        useOwnClickExposeAnalysis: true, // 是否使用自己的点击，曝光埋点
      }
    })

    return {
      itemConfig,
    }
  },
  data() {
    return {
      LAZY_IMG,
      exposeCode: 'style-to-match',
    }
  },
  computed: {
    ...mapGetters('productDetail/common', [
      'language_v2', 
      'is_hit_compliance_mode',
      'is_new_size_local'
    ]),
    ...mapGetters('productDetail/Recommend', [
      'product_bff_depend_data',
    ]),
    ...mapGetters('productDetail/Middle', ['relation_data']),
  },
  mounted() {
    this.$nextTick(() => {
      this.initExpose()
    })
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updateCurrentLocalCountry']),
    exposeCallback(params) {
      recommendExposureListInstance.pushQueen({
        targets: params?.targets || [],
      })
    },
    openPopup({ list, mainImg, theme_name }, $event) {
      const { itemConfig } = this
      this.$selectionPopup.open({
        list,
        mainImg,
        productBffDependData: this.product_bff_depend_data,
        slideClass: 'main-img__popup',
        title: `${theme_name} (${list.length})`,
        openName: ['page_goods_detail'],
        itemConfig,
        actionCallBack: {
          switchColor: (item) => {
            this.clickPopupEvent([item])
            this.exposePopupEvent([item])
          },
          clickItem: (item) => {
            this.clickPopupEvent([item])
          },
          clickCart: (status, index) => {
            status && status && this.clickQuikEvt($event.target, list[index], index)
          },
          clickCartDetail: (index) => {
            this.detailClickHandle($event.target, list[index], index)
          },
        },
        analysisConfig: {
          from: 'other_options',
          exposeCode: 'style-to-match__popup',
          exposeId: '1-6-4-29',
          quickAddAnalysis: {
            code: 'otherOptions',
            from: 'theOptions',
            sa: {
              from: 'other_options',
            },
          },
        },
        router: this.$router,
        exposeCallback: (params) => {
          recommendExposureListInstance.pushQueen({
            targets: params?.targets || [],
          })
        },
      })
    },
    initExpose() {
      daEventExpose.subscribe &&
        daEventExpose.subscribe({
          keycode: this.exposeCode + '`1-6-4-27',
        })
    },
    clickPopupEvent(list = []) {
      daEventCenter.triggerNotice({
        daId: '1-6-4-30',
        extraData: {
          activity_from: 'other_options',
          goods_list: this.getGoodsList(list),
        },
      })
    },
    exposePopupEvent(list = []) {
      daEventCenter.triggerNotice({
        daId: '1-6-4-29',
        extraData: {
          activity_from: 'other_options',
          goods_list: this.getGoodsList(list),
        },
      })
    },
    getGoodsList(list = []) {
      if (!list.length) return ''
      // goods_list goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark`置顶标识extra_mark`pri_在售价格｜pri_原价（美元，保留小数点两位）`其它标识
      return list
        .map(
          (item, index) =>
            `${item.goods_id}\`${item.goods_sn}\`${item.productRelationID}\`${
              index + 1
            }\`1\`\`\`\`pri_${item.salePrice.usdAmount}|pri_${
              item.retailPrice.usdAmount
            }\`\`\`mall_${item.mall_code}`
        )
        .join(',')
    },
    async openQuickAdd(item, index, $event) {
      const { goods_id = '', goods_img } = item
      const imgRatio = await getImageRatio(goods_img)
      this.$quickAdd.open({
        goods_id,
        imgRatio,
        addSource: 'detailPage',
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !this.is_hit_compliance_mode,
        analysisConfig: {
          code: 'otherOptions',
          from: 'theOptions',
          sa: {
            from: 'other_options',
          },
        },
        clickCallBack: {
          isActive: (status) => status && this.clickQuikEvt($event.target, item, index),
          detail: () => this.detailClickHandle($event.target, item, index),
          handleChangeLocalCountry: (value) => {
            // 数据联动控制
            this.is_new_size_local && this.updateCurrentLocalCountry(value)
          },
          // 加车成功后的回调
          complete: () => {
            setTimeout(() => {
              window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
          },
        },
      })
    },
    clickQuikEvt(target, item, index) {
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target,
        extraData: {
          goodsListParams: {
            sku: item.goods_sn,
            spu: item.productRelationID || item.goods_sn,
            goodsId: item.goods_id,
            originPrice: item.retailPrice.usdAmount,
            price: item.salePrice.amount,
            mallCode: item.mall_code,
            index,
          },
        },
      })
    },
    detailClickHandle(target, detail, index) {
      daEventCenter.triggerNotice({
        daId: '2-20-1',
        // target,
        extraData: {
          detail,
          index,
          activityFrom: 'other_options',
          style: 'popup',
        },
      })
    },
    transformImg,
  },
  render () {
    if(this.relation_data.length === 0) return null
    let relationEl = () => {
      return Object.keys(this.relation_data).map(series => {
        let data = this.relation_data[series]
        let mainImgMain = (
          <div
            class="style-to-match__group-banner"
            da-event-click="1-6-4-28"
            onClick={e => this.openPopup(data, e)}
          >
            <div class="style-to-match__img-banner">
              <img
                data-src={data.mainImg}
                data-design-width="0"
                class="lazyload"
              />
            </div>
            {data.list.map((item, index) => {
              if (index < 2) {
                return (
                  <img
                    key={index}
                    data-src={transformImg({ img: item.goods_img })}
                    src={this.LAZY_IMG}
                    data-design-width="0"
                    class="style-to-match__img-child lazyload"
                  />
                )
              }
              return null
            })}
            {data.list.length > 2 ?
              <div class="style-to-match__more">
                +{data.list.length - 2}
              </div> : null
            }
          </div>
        )
        let mainImgName = (
          <div class="style-to-match__name">
            # {data.theme_name}
          </div>
        )
        let group = data.list.length < 3 ?
          data.list.map((item, index) => {
            return (
              <div
                key={index}
                da-event-click="1-6-4-28"
                class="style-to-match__group"
                onClick={e => this.openQuickAdd(item, index, e)}
              >
                <div class="style-to-match__img-main">
                  <img
                    src={LAZY_IMG}
                    data-src={transformImg({ img: item.goods_img })}
                    data-design-width="0"
                    class="lazyload"
                  />
                </div>
                <div class="style-to-match__name"># { data.theme_name }</div>
              </div>
            )
          })
          :
          (<div
            class="style-to-match__group"
            da-event-click="1-6-4-28"
            onClick={e => this.openPopup(data, e)}
          >
            <div class="style-to-match__img-multi">
              {
                data.list.map((item, index) => {
                  if (index < 4) {
                    return (
                      <img
                        key={index}
                        data-src={transformImg({ img: item.goods_img })}
                        src={this.LAZY_IMG}
                        data-design-width="0"
                        class="lazyload"
                      />
                    )
                  }
                  return null
                })
              }
              {data.list.length > 4 ?
                <div class="style-to-match__more">
                  +{data.list.length - 4}
                </div> : null
              }
            </div>
            <div class="style-to-match__name"># { data.theme_name }</div>
          </div>)
        return (
          <div
            key={series}
            class={[
              'style-to-match__swiper-slide',
              { 'style-to-match__slide-main': data.mainImg },
              { 'style-to-match__slide': !data.mainImg },
            ]}
          >
            { data.mainImg ? mainImgMain : null }
            { data.mainImg ? mainImgName : null }
            { !data.mainImg ? group : null } 
          </div>
        )
      })
    }
    return (
      <div
        class="style-to-match"
        da-expose-code={this.exposeCode}
        data-floor-tab="styleMatching"
      >
        <div class="style-to-match__title">
          { this.language_v2.SHEIN_KEY_PWA_14646.toLowerCase() }
        </div>
        <div
          class="style-to-match__wrap j-da-event-box"
          da-event-expose="1-6-4-27"
          data-title="otherOptions"
          data-active-from="other_options"
        >
          <div
            class="style-to-match__swiper"
          >
            {relationEl()}
          </div>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.style-to-match {
  background: #fff;
  padding: 0.2667rem 0.32rem 0.2667rem 0.32rem;
  margin-bottom: 0.213333rem;
  margin-top: 0.213333rem;
  .style-to-match__swiper {
    overflow-x: auto;   /* 横向滚动 */
    white-space: nowrap; /* 不换行 */
    &::-webkit-scrollbar { // 隐藏横向滚动条
      display: none;
    }
    .style-to-match__swiper-slide {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .style-to-match__slide-main {
    width: 2.76rem;
  }
  .style-to-match__slide {
    width: auto;
  }
  &__title {
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0.16rem;
    text-transform: capitalize;
    /* rw:begin */
    font-family: 'Adieu';
  }
  &__img-main {
    width: 100%;
    height: 4.62rem;
    img {
      width: 100%;
    }
  }
  &__img-multi {
    width: 100%;
    height: 4.62rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    background: #f6f6f6;
    img {
      width: calc(1.73rem - 1px);
      height: calc(2.31rem - 1px);
    }
  }
  .style-to-match__group {
    display: inline-block;
    width: 3.46rem;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
  .style-to-match__group-banner {
    width: 2.76rem;
    height: 4.62rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    position: relative;
    background: #f6f6f6;
  }
  &__img-child {
    width: calc(50% - 1px);
    height: calc(1.86rem - 2px);
  }
  &__img-banner {
    height: 2.76rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__name {
    margin-top: 0.32rem;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__more {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0.467rem;
    width: 0.853rem;
    text-align: center;
    line-height: 0.467rem;
  }
}
</style>
