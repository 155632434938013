<template>
  <div
    class="tips"
    :style="isAllLabelsDisplayed ? singleLineStyle : null "
  >
    <template v-if="tipsData.type == 'store'">
      <!-- 时尚店铺 -->
      <div
        v-if="shouldShowLabel(labelKeys[0])"
        v-expose="{id: '1-6-4-75'}"
        class="lable_container fashion-icon"
      >
        <img
          v-if="inFashionStoreImg"
          class="fashion-icon__bff-icon"
          :src="inFashionStoreImg"
        />
        <span
          v-else
          class="fashion-icon__default"
        >
          {{ textCombo.trendsLang || 'Trends' }} 
          <img
            class="fashion-icon__default-icon"
            :src="`${PUBLIC_CDN}/pwa_dist/images/store/infashion_label/default-fd2954326c.png`"
          />
        </span>
      </div>
      <!-- 品质店铺 -->
      <div
        v-if="shouldShowLabel(labelKeys[1])"
        class="lable_container choices-icon"
      >
        <img :src="inQualityStoreImg" />
      </div>
      <!-- 优选卖家 -->
      <div
        v-if="shouldShowLabel(labelKeys[2])"
        class="lable_container local_tip orange"
      >
        <div class="seller-icon">
          <img :src="`${PUBLIC_CDN}/pwa_dist/images/store/store_preferred_seller-bb758e2772.png`" />
        </div>
        <div class="seller-text">
          {{ tipsData.sellerText }}
        </div>
      </div>
      <!-- 本地卖家 -->
      <div
        v-if="shouldShowLabel(labelKeys[3])"
        class="lable_container local_tip green"
        :class="{ 'split-left': tipsData.isBestSeller }"
      >
        {{ tipsData.saleTagText }}
      </div>
    </template>
    <!-- 有新的再扩展吧 -->
    <template v-else>
      <div class="brand-serial_tip">
        {{ tipsData.labelText }}
      </div>
    </template>

    <slot name="extend-content"></slot>
  </div>
</template>

<script>
const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'BrandSeriesTips',
  props: {
    inFashionStoreImg: {
      type: String,
      default: ''
    },
    inQualityStoreImg: {
      type: String,
      default: ''
    },
    tipsData: {
      type: Object,
      default: () => {}
    },
    // 标签是否全部展示
    isAllLabelsDisplayed: {
      type: Boolean,
      default: false
    },
    // 多语言
    textCombo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PUBLIC_CDN,
      // 标签标识, 这里要按顺序写，有优先级， ui要展示前两项
      labelKeys: ['isInFashionStore', 'isQualityStore', 'isBestSeller', 'saleTagText'],
      // 单行样式
      singleLineStyle: {
        flexWrap: 'wrap',
        maxHeight: '0.4267rem', // 一行最大高度跟lable_container保持一致，也可以使用计算属性根据需要展示的标签动态设置最大高度
        overflow: 'hidden',
      }
    }
  },
  computed: {
    // 需要展示的标签数据，只展示前两项
    visibleLabels() {
      const arr = []
      for (let key of this.labelKeys) {
        if (this.tipsData && this.tipsData[key]) {
          arr.push({ key, show: true })
          if (!this.isAllLabelsDisplayed && arr.length === 2) break // 不需要全部展示时，当找到两个标签时，提前终止循环
        }
      }
      return arr
    }
  },
  methods: {
    // 是否需要展示标签
    shouldShowLabel(key) {
      return !!this.visibleLabels.find(v => v.key === key)
    },
  }
}
</script>

<style lang="less">
.mgds-brand {
  .tips {
    display: inline-flex;
    align-items: center;
    .store-text {
      max-width: 4.23rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .lable_container{
      height: 0.4267rem;
    }
    .local_tip {
      position: relative;
      display: inline-flex;
      padding: 0 0.1067rem;
      height: 0.3733rem;
      align-items: center;
      .font-dpr(20px);
      font-weight: 400;
      white-space: nowrap;
      border-radius: 2px;
      padding: 0 3/37.5rem;
      font-family: 'SF-UI-Text-Regular';
      /* stylelint-disable-next-line */
      .seller-icon {
        position: absolute;
        top: 1/75rem;
        left: 6/75rem;
        width: 24/75rem;
        height: 24/75rem;
        /* stylelint-disable-next-line */
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      /* stylelint-disable-next-line */
      .seller-text {
        padding-left: 28/75rem;
      }
    }
    .fashion-icon {
      margin-right: 8/75rem;
      min-width: 1.1733rem;
      display: flex;
      // width: 120/75rem;
      &__default{
        height: 14px;
        line-height: 14px;
        position: relative;
        display: inline-block;
        padding: 0 5px 0 2px;
        font-size: 11px;
        font-style: italic;
        font-weight: 858;
        color: #fff;
        z-index: 1;
      }
      &__default-icon{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      img {
        width: 100%;
        height: 100%;
      }
      &__bff-icon{
        height: 0.37333rem !important; // 14px
        width: auto !important;
      }
    }
    .choices-icon{
      margin-right: 8/75rem;
      width: 1.36rem;
      img {
        display: block;
        width: 100%;
      }
    }
    .green {
      color: @sui_color_safety;
      background: @sui_color_safety_bg;
      border: 0.5px solid rgba(25, 128, 85, 0.15);
    }
    .orange {
      color: @sui_color_micro_emphasis;
      background: @sui_color_micro_emphasis_bg;
      border: .5px solid rgba(168, 97, 4, 0.15);
    }

    .brand-serial_tip {
      padding: 0 4/75rem;
      line-height: 30/75rem;
      text-align: center;
      font-size: 10px;
      font-weight: 500;
      color: #2d3540;
      background: linear-gradient(128.64deg, #e2edf3 7.72%, #f0f8ff 34.23%, #dae7f6 98.52%);
      border: 0.5px solid #bcc8d3;
      border-radius: 4/75rem;
    }
  }
}
</style>
